<template>
  <component
    :is="component"
    ref="buttonRef"
    v-ripple="{ early: true }"
    :class="classList"
    v-bind="linkProps"
    :type="typeComputed"
    @click="onClick"
  >
    <div class="button__inner">
      <div v-if="$slots.prepend" class="button__prepend">
        <slot name="prepend" />
      </div>

      <ui-icon
        v-if="icon"
        :icon="icon"
        class="button__icon"
      />

      <div v-if="hasLabel || $slots.label" class="button__label">
        <slot name="label" />
        {{ label }}
      </div>

      <app-badge v-if="badge !== undefined" :color="badgeColor" class="button__badge">{{ badge }}</app-badge>

      <ui-icon v-if="iconRight" :icon="iconRight" class="button__icon button__icon-right" />

      <div v-if="$slots.append" class="button__append">
        <slot name="append" />
      </div>
    </div>

    <app-tooltip v-if="tooltip">
      <span v-html="tooltip" />
    </app-tooltip>

    <transition name="fade">
      <div v-if="loading" class="button__spinner">
        <app-spinner class="button__spinner-element" />
      </div>
    </transition>

    <slot />
  </component>
</template>

<script setup lang="ts">
import UiIcon from 'src/components/ui/UiIcon.vue'
import type { Props as BadgeProps } from 'src/components/ui/AppBadge.vue'
import { ref, computed } from 'vue'
import type { Size } from 'src/types/enum'
import type { RouteLocationRaw } from 'vue-router'

type Tag = 'button' | 'router-link' | 'a'
type Color = 'primary' | 'primary-muted' | 'light' | 'dark' | 'translucent' | 'transparent' | 'muted' | 'accent'
type Align = 'left' | 'center'

export interface Props {
  tag?: Tag,
  label?: string | number,
  loading?: boolean,
  icon?: string,
  caps?: boolean,
  iconRight?: string,
  color?: Color,
  align?: Align
  shadow?: boolean
  outline?: boolean,
  round?: boolean
  square?: boolean
  rounded?: boolean
  noRadius?: boolean
  to?: RouteLocationRaw
  href?: string
  target?: string
  size?: `${Size}`
  disabled?: boolean
  inactive?: boolean
  fullWidth?: boolean
  tooltip?: string
  noHover?: boolean
  badge?: string | number
  badgeColor?: BadgeProps['color']
  muted?: boolean
  type?: string
}

interface Emits {
  (e: 'click'): void
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'button',
  rounded: false,
  size: 'md',
  fullWidth: false,
  outline: false,
  color: 'light',
  align: 'center',
  inactive: false,
  noHover: false,
  noRadius: false,
  shadow: false
})

const emit = defineEmits<Emits>()

const buttonRef = ref<HTMLInputElement | null>(null)

const classList = computed(() => ([
  'button',
  `button--size-${props.size}`,
  `button--color-${props.color}`,
  `button--align-${props.align}`,
  {
    'button--shadow': props.shadow,
    'button--loading': props.loading,
    'button--disabled': props.disabled,
    'button--inactive': props.inactive,
    'button--full-width': props.fullWidth,
    'button--caps': props.caps,
    'button--rounded': props.rounded,
    'button--square': props.square,
    'button--round': props.round,
    'button--outline': props.outline,
    'button--no-radius': props.noRadius,
    'button--muted': props.muted,
    'button--no-hover': props.noHover
  }
]))

const component = computed(() => {
  if (props.to) return 'router-link'
  if (props.href) return 'a'
  return props.tag
})

const linkProps = computed(() => {
  const { to, href, target } = props

  if (to) return { to }
  if (href) return { href, target }
  return {}
})

const typeComputed = computed(() => {
  return component.value === 'button' ? props.type ?? 'button' : undefined
})

const hasLabel = computed(() => typeof props.label !== 'undefined')

function onClick() {
  if (!props.disabled && !props.loading) emit('click')
}

defineExpose({ buttonRef })
</script>

<style lang="scss" scoped>
.button {
  --focus-opacity: 0.15;
  --focus-color: var(--color);
  --font-size: 14px;
  --icon-size: 18px;
  --bg: transparent;
  --line-height: 1.35;
  --font-weight: 400;
  --radius: var(--sm);
  --ps: var(--px);
  --pe: var(--px);
  --align: center;
  --muted-opacity: 0.5;

  position: relative;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
  color: var(--color-text);
  min-height: var(--size);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  text-align: var(--align);
  padding: var(--py) var(--pe) var(--py) var(--ps);
  border-radius: var(--radius);
  cursor: pointer;
  transition: background-color var(--trs-1), color var(--trs-1), border-color var(--trs-1), opacity var(--trs-1);

  &::after, &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &::before {
    background-color: var(--focus-color);
    transition: background-color var(--trs-1);
  }

  &::after {
    background-color: var(--color-text);
    opacity: 0;
    transition: opacity var(--trs-1);
    will-change: opacity;
  }

  &__inner {
    display: flex;
    position: relative;
    z-index: 1;
    gap: var(--gap);
    align-items: center;
    text-align: var(--align);
    justify-content: var(--align);
    width: 100%;
    transition: opacity var(--trs-1);
  }

  &__icon {
    font-size: var(--icon-size);
  }

  &__spinner {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &-element {
      display: block;
      width: var(--spinner-size);
      height: var(--spinner-size);
    }
  }

  body.desktop &:hover {
    &:not(.button--no-hover):not(.button--inactive):not(.button--disabled) {
      &::after {
        opacity: var(--focus-opacity);
      }
    }
  }

  /* color */
  &--color-primary {
    --color: var(--theme-color-primary);
    --color-text: var(--theme-dark-color);
  }

  &--color-accent {
    --color: var(--theme-color-accent);
    --color-text: var(--color-light);
  }

  &--color-primary-muted {
    --color: var(--theme-color-primary-muted);
    --color-text: var(--theme-color-primary);
  }

  &--color-muted {
    --color: var(--theme-color-muted-light);
    --color-text: var(--theme-color);
    &.button--outline {
      --color: var(--theme-color-muted-light);
      --color-text: var(--theme-color-muted);
    }
  }

  &--color-light {
    --color: var(--theme-color-light);
    --color-text: var(--theme-color);
    --focus-opacity: 0.05;
    &.button--outline {
      --color: var(--theme-color-separator);
      --color-text: var(--theme-color);
    }
  }

  &--color-light-muted {
    --color: var(--theme-color-bg);
    --color-text: var(--theme-color);
    --focus-opacity: 0.05;
    --color-border: var(--theme-color-separator);
    &.button--disabled {
      --color: var(--theme-color-muted);
    }
  }

  &--color-transparent {
    --color: transparent;
    --color-text: var(--theme-color);
  }

  &--color-dark {
    --color: var(--color-dark);
    --color-text: var(--color-light);
    body.body--dark & {
      --color: var(--color-light);
      --color-text: var(--color-dark);
    }
  }

  &--color-translucent {
    --color: var(--theme-color-bg-translucent);
    --color-text: var(--theme-color-muted);
  }
  /* color */
  &--size-xs {
    --size: 28px;
    --gap: 6px;
    --px: 10px;
    --py: 6px;
    --spinner-size: 12px;
  }

  &--size-sm {
    --size: 36px;
    --gap: 8px;
    --px: 14px;
    --py: 6px;
    --spinner-size: 18px;
  }

  &--size-md {
    --size: 40px;
    --gap: 8px;
    --px: 16px;
    --py: 8px;
    --spinner-size: 20px;
  }

  &--size-lg {
    --size: 48px;
    --font-size: 16px;
    --icon-size: 22px;
    --gap: 12px;
    --px: 24px;
    --py: 8px;
    --spinner-size: 22px;
  }

  &--muted {
    &::before {
      opacity: var(--muted-opacity);
    }
  }

  &--align-center {
    --align: center;
  }

  &--align-left {
    --align: left;
  }

  &--no-radius {
    --radius: 0px;
  }

  &--rounded {
    --radius: calc(var(--size) / 2);
  }

  &--square, &--round {
    --px: 0px;
    --py: 0px;
    min-width: var(--size);
  }

  &--round {
    --radius: 50%;
  }

  &--outline {
    --color-text: var(--color);
    border: 1px solid var(--color);
    &::before {
      background-color: var(--theme-color-bg);
    }
  }

  &--full-width {
    width: 100%;
  }

  &--caps {
    text-transform: uppercase;
  }

  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }

  &--inactive {
    cursor: default;
    pointer-events: none;
  }

  &--loading {
    cursor: progress;
    .button__inner {
      opacity: 0;
    }
  }

  &--shadow {
    box-shadow: var(--shadow);
  }
}
</style>
